import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useLocation } from '@reach/router'
import styled from "styled-components"
import AGBTemplate from "../components/AGB/agb"

const StyledWrapper = styled.div`
  margin: 2rem 5vw;
`

export default function Page() {
  const location = useLocation();
  let country ="CH"
  let lang="de-CH"
  
  const url = typeof window !=="undefined" ? window.location.href :"";


  if(url.includes(".com")){
    country ="DE"
  }
  return (
    <Layout location={country} path={location.pathname} language={lang}>
      <Seo
        title="Nutzungsbedingungen DoryGo "
        description="Hier finden Sie die Nutzungsbedingunge DoryGo App"
      />
      <StyledWrapper>
          <AGBTemplate/>
      </StyledWrapper>
    </Layout>
  )
}